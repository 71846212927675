var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"column is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"title"},[_vm._v("Ciclos")]),_c('router-link',{staticClass:"button is-success",attrs:{"to":"/dashboard/ciclos/iniciar-ciclo"}},[_vm._v("Iniciar ciclo")])],1),_c('div',{staticClass:"column is-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ciclos,"item-key":"id_ciclo","footer-props":{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                              name: 'EditarCiclo',
                              params: { id: item.id_ciclo },
                          }}},[_vm._v(" Editar ")])]}},{key:"item.qt_rs",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nonePara(item.qt_rs))+" ")]}},{key:"item.qt_mk",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nonePara(item.qt_mk))+" ")]}},{key:"item.ic_concluido",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.binarioParaBool(item.ic_concluido))+" ")]}}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }