<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">
          Separar empresas ciclo {{ this.$route.params.ciclo }}
        </h1>
      </div>
      <div class="column is-12">
        <form>
          <div class="field has-addons">
            <div class="control mx-2">
              <input
                type="number"
                class="input"
                v-model="n_empresas"
                placeholder="Quantidade fichas"
                min="0"
              />
            </div>
            <div class="select mx-2">
              <select v-model="filtro_ficha">
                <option
                  select
                  value=""
                >Selecionar Ficha</option>
                <option value="Marketplace">Marketplace</option>
                <option value="Redes Sociais">Redes Sociais</option>
              </select>
            </div>
            <div class="select mx-2">
              <select v-model="filtro_er">
                <option
                  select
                  value=""
                >Selecionar ER</option>
                <option
                  v-for="er in definirErs()"
                  v-bind:key="er"
                  :value="er"
                >{{ er }}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-12">
        <div
          class="box"
          style="height: 180px; overflow-x: auto"
        >
          <div class="subtitle">
            Empresas - {{ filtrarEmpresas().length }}
          </div>
          <Container
            orientation="horizontal"
            group-name="1"
            :get-child-payload="getCardPayloadEmpresas"
            @drop="onDrop('empresas', $event)"
            v-bind:class="classObject"
            style="min-width: 400px; min-height: 40px;"
          >
            <Draggable
              v-for="item in filtrarEmpresas()"
              :key="item.id_atendimento"
            >
              <v-chip
                class="draggable-item empresa"
                :color="definirCor(item)"
              >
                {{ item.nome_fantasia }}
                {{ item.fk_id_po }}
              </v-chip>
            </Draggable>
          </Container>
        </div>
      </div>
      <div class="column is-12">
        <div class="field has-addons">
          <button
            class="button is-info botoes-empresa"
            @click="adicionarTodos()"
          >
            Adicionar todos
          </button>
          <div class="select">
            <select v-model="adicionar_po">
              <option value="">Selecionar PO</option>
              <option
                v-for="po in pos"
                v-bind:key="po.id_pessoa"
                :value="po.id_pessoa"
              >{{ po.nome }}</option>
            </select>
          </div>
          <div class="control mx-2">
            <input
              type="number"
              class="input"
              v-model="quantidade_fichas"
              placeholder="Quantidade fichas"
              min="0"
            />
          </div>
          <div class="tamanho-po">
            <div class="subtitle has-text-centered">
              {{ pegarTamanhoPo() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div
        v-for="po in pos"
        v-bind:key="po.id_pessoa"
      >
        <div
          v-if="po.empresas.length"
          class="column is-12"
        >
          <div class="box">
            <div class="subtitle">
              {{ po.nome }} -
              {{ po.empresas.length }}
            </div>
            <Container
              v-bind:class="classObject"
              group-name="1"
              :get-child-payload="getCardPayload(po.id_pessoa)"
              @drop="e => onCardDrop(po.id_pessoa, e)"
            >
              <Draggable
                v-for="item in po.empresas"
                :key="item.id_atendimento"
              >
                <v-chip
                  class="draggable-item empresa"
                  :color="definirCor(item)"
                >
                  {{ item.nome_fantasia }}
                  {{ item.numero_demanda }}
                </v-chip>
              </Draggable>
            </Container>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <button
          class="button is-success"
          @click="entregar()"
        >
          Entregar
        </button>
        <botao-voltar />
      </div>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-dndrop';
import { applyDrag, generateItems } from '../../../utils/helpers';
import axios from 'axios';
import { toast } from 'bulma-toast';
import BotaoVoltar from '../../../components/BotaoVoltar';

export default {
  name: 'SepararEmpresas',

  components: { Container, Draggable, BotaoVoltar },

  data() {
      return {
          classObject: {
              dotted: true,
              'drag-container': true,
          },
          pos: [{ id_pessoa: -1, nome: 'Trocar de ciclo', empresas: [] }],
          empresas: [],
          pagina: 1,
          adicionar_po: '',
          filtro_er: '',
          opcoes_er: '',
          filtro_ficha: '',
          quantidade_fichas: 20,
          n_empresas: 500,
      };
  },

  mounted() {
      this.getEmpresas();
  },

  methods: {
      pegarTamanhoPo() {
          var po = this.pos.filter(p => p.id_pessoa == this.adicionar_po);
          if (!po.length) {
              return '';
          }
          return po[0].empresas.length;
      },
      definirCor(empresa) {
          if (empresa.ficha === 'Marketplace') {
              return '#ffa500';
          }
          return '#FFFF00';
      },

      modificarDrop(dropResult) {
          if (dropResult.addedIndex != null) {
              return dropResult;
          }
          let nova_payload = this.filtrarEmpresas()[dropResult.removedIndex];
          let index_novo = this.empresas.indexOf(nova_payload);
          const dropResultMod = {
              removedIndex: index_novo,
              addedIndex: null,
              payload: nova_payload,
          };
          return dropResultMod;
      },

      onDrop(collection, dropResult) {
          let dropResultMod = this.modificarDrop(dropResult);
          // console.log(dropResultMod)
          this[collection] = applyDrag(this[collection], dropResultMod);
      },

      onCardDrop(id_pessoa, dropResult) {
          // let dropResultMod = this.modificarCardDrop(dropResult)
          this.pos.filter(
              p => p.id_pessoa == id_pessoa
          )[0].empresas = applyDrag(
              this.pos.filter(p => p.id_pessoa == id_pessoa)[0].empresas,
              dropResult
          );
      },

      getCardPayloadEmpresas(index) {
          return this.filtrarEmpresas()[index];
      },

      getCardPayload(id_pessoa) {
          return index => {
              return this.pos.filter(p => p.id_pessoa === id_pessoa)[0]
                  .empresas[index];
          };
      },

      filtrarEmpresas() {
          const tipo_ficha = this.filtro_ficha;
          const er = this.filtro_er;

          var empresas_colocadas = 0;
          for (let i = 0; i < this.pos.length; i++) {
              const empresas_po = this.pos[i].empresas;
              empresas_colocadas += empresas_po.length;
          }

          var empresas = this.empresas;

          if (!tipo_ficha && !er) {
              var resultado = empresas;
          } else if (tipo_ficha && !er) {
              var resultado = empresas.filter(
                  empresa => empresa.ficha === tipo_ficha
              );
          } else if (!tipo_ficha && er) {
              var resultado = empresas.filter(empresa => empresa.er === er);
          } else {
              var resultado = empresas
                  .filter(empresa => empresa.ficha === tipo_ficha)
                  .filter(empresa => empresa.er === er);
          }

          if (empresas_colocadas >= this.n_empresas) {
              this.n_empresas = empresas_colocadas;
          }

          return resultado.slice(0, this.n_empresas - empresas_colocadas);
      },

      async getEmpresas() {
          this.$store.commit('setIsLoading', true);

          function compare(a, b) {
              if (a.numero_demanda < b.numero_demanda) return -1;
              if (a.numero_demanda > b.numero_demanda) return 1;
              return 0;
          }

          await axios
              .get(`/api/v1/empresas/?ciclo=${this.$route.params.ciclo}`)
              .then(response => {
                  this.empresas = response.data.filter(
                      e => this.$route.params.ciclo == e.ciclo_triagem
                  );
                  this.empresas.sort(compare);
                  this.opcoes_er = [
                      ...new Set(this.empresas.map(item => item.er)),
                  ];
              })
              .catch(error => {
                  console.log(error);
              });

          await axios
              .get('/api/v1/pessoas/')
              .then(response => {
                  let pessoas = response.data.filter(p => p.funcao === 'PO' && p.ativo);
                  for (let i = 0; i < pessoas.length; i++) {
                      if (pessoas[i].fk_id_po == 2) {
                          continue;
                      }
                      let empresas_po = this.empresas.filter(
                          e => e.fk_id_po == pessoas[i].id_pessoa
                      );
                      for (let j = 0; j < empresas_po.length; j++) {
                          const index = this.empresas.indexOf(empresas_po[j]);
                          if (index > -1) {
                              this.empresas.splice(index, 1);
                          }
                      }
                      pessoas[i].empresas = empresas_po;
                      this.pos.push(pessoas[i]);
                  }
              })
              .catch(error => {
                  console.log(error);
              });

          this.$store.commit('setIsLoading', false);
      },

      definirErs() {
          const tipo_ficha = this.filtro_ficha;
          const er = this.filtro_er;

          var empresas_colocadas = 0;
          for (let i = 0; i < this.pos.length; i++) {
              const empresas_po = this.pos[i].empresas;
              empresas_colocadas += empresas_po.length;
          }

          var empresas = this.empresas;

          if (!tipo_ficha) {
              var resultado = empresas;
          } else {
              var resultado = empresas.filter(
                  empresa => empresa.ficha === tipo_ficha
              );
          }
          resultado = resultado.slice(
              0,
              this.n_empresas - empresas_colocadas
          );
          return [...new Set(resultado.map(item => item.er))];
      },

      adicionarTodos() {
          const po_escolhido = this.adicionar_po;

          if (po_escolhido === '') {
              alert('Escolha um po para adicionar as empresas');
              return;
          }

          const tamanho = this.quantidade_fichas;
          const empresas = this.empresas;
          const empresas_filtradas = this.filtrarEmpresas();
          const tamanho_empresas_filtradas = this.filtrarEmpresas().length;

          const objeto_po = this.pos.filter(
              p => p.id_pessoa == po_escolhido
          )[0];
          if (po_escolhido === -1) {
              this.quantidade_fichas = this.empresas.length;
              this.filtro_er = '';
              this.filtro_ficha = '';

              for (let i = 0; i < empresas.length; i++) {
                  const empresa = this.empresas[0];

                  const index_normal = this.empresas.indexOf(empresa);

                  const dropResultNormal = {
                      addedIndex: null,
                      payload: empresa,
                      removedIndex: index_normal,
                  };
                  const dropResultCard = {
                      addedIndex: 0,
                      payload: empresa,
                      removedIndex: null,
                  };

                  this.onCardDrop(po_escolhido, dropResultCard);
                  this.empresas = applyDrag(this.empresas, dropResultNormal);
              }
          }
          for (let i = 0; i < tamanho; i++) {
              if (tamanho_empresas_filtradas <= i) {
                  break
              }
              const empresa = this.empresas.filter(
                  e =>
                      e.id_atendimento == empresas_filtradas[i].id_atendimento
              )[0];

              const index_normal = this.empresas.indexOf(empresa);

              const dropResultNormal = {
                  addedIndex: null,
                  payload: empresa,
                  removedIndex: index_normal,
              };
              const dropResultCard = {
                  addedIndex: 0,
                  payload: empresa,
                  removedIndex: null,
              };

              this.onCardDrop(po_escolhido, dropResultCard);
              this.empresas = applyDrag(this.empresas, dropResultNormal);
          }
      },

      async entregar() {
          this.$store.commit('setIsLoading', true);

          let resultado = {};
          this.pos.forEach(pessoa => {
              if (pessoa.id_pessoa > 0) {
                  console.log(resultado)
                  resultado[pessoa.id_pessoa] = pessoa.empresas;
              }
          });
          if (resultado){
            toast({
                      message: "Carregando...",
                      type: 'is-warning',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 4000,
                      position: 'bottom-right',
                  })
                await axios
              .put('/api/v1/empresas/separar_empresas/1/', resultado)
              .then(response => {
                  toast({
                      message: "Empresas alteradas para os respectivos PO's",
                      type: 'is-success',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 4000,
                      position: 'bottom-right',
                  })
              })
              .catch(error => {
                  console.log(error);
                  this.$store.commit('setIsLoading', false);
              });
          }

          var ciclo = this.$route.params.ciclo;
          var empresas_trocar_ciclo = this.pos.filter(
              p => p.id_pessoa == -1
          )[0].empresas;

          if (empresas_trocar_ciclo.length) {
            await axios
                .put(
                  `/api/v1/empresas/atualizar_ciclos/${parseInt(ciclo) +
                      1}/`,
                  empresas_trocar_ciclo
                )
                .then(response => {
                  console.log(response);
                  toast({
                      message:
                          "Empresas adicionadas aos respectivos PO's",
                      type: 'is-success',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 2000,
                      position: 'bottom-right',
                  });
                  this.$router.push('/dashboard/ciclos');
                  this.$store.commit('setIsLoading', false);
                })
                .catch(error => {
                    console.log(error);
                    this.$store.commit('setIsLoading', false);
                });
          }
      },
  },
};
</script>

<style lang="css" scoped>
.dotted {
  outline: 1px dotted #000;
}
.drag-container {
  min-height: 20px;
  margin-bottom: 30px;
}
.botoes-empresa {
  margin-right: 10px;
}
.empresa {
  margin: 5px;
}
.coluna {
  display: flex;
  justify-content: stretch;
  margin: 50px;
}
.groups {
  display: flex;
  justify-content: stretch;
  margin-top: 10px;
  margin-right: 50px;
}

.group {
  margin-left: 50px;
  flex: 1;
}

.tamanho-po {
  text-align: center;
  padding: 5px;
}
</style>
