var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"column is-multiline"},[_vm._m(0),_c('div',{staticClass:"column is-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ers,"item-key":"id_er","footer-props":{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                              name: 'Er',
                              params: { id: item.id_er },
                          }}},[_vm._v(" Editar ")])]}}],null,true)})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"title"},[_vm._v("Escritórios Regionais")])])}]

export { render, staticRenderFns }