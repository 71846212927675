<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Produtos - {{ empresa.nome_fantasia }} - {{ empresa.id_atendimento }}</h1>
      </div>
      <div
        class="column is-4"
        v-for="produto in produtos"
        v-bind:key="produto.id"
      >
        <div class="box">
          <h2 class="subtitle">{{ produto.f2_nome_prod }}</h2>
          <div
            v-for="info in legenda_produtos[prefix]"
            v-bind:key="info.legenda"
          >
            <p v-if="produto[info.valor]"><strong>{{ info.legenda }}: </strong>{{ produto[info.valor] }}</p>
          </div>
          <router-link
            :to="{
                            name: 'Produto',
                            params: { id: empresa.id_atendimento, produto: produto[`id_prod_${prefix}`] },
                        }"
            class="button is info is-small"
          >Editar</router-link>

        </div>
      </div>
      <div class="buttons column is-12">
        <p v-if="!produtos.length">Esta empresa não tem nemhum produto</p>
        <router-link
          :to="{
                            name: 'Empresa',
                            params: { id: empresa.id_atendimento},
                        }"
          class="button is-info"
        >Voltar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { legenda_produtos } from '../../settings.js'
export default {
    name: 'Produtos',
    data() {
        return {
            produtos: [],
            empresa: {},
            info_adicional: {},
            prefix: '',
            legenda_produtos: {},
            ficha: '',
        };
    },
    mounted() {
        this.getEmpresa();
    },
    methods: {
        async getEmpresa() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get(`/api/v1/empresas/${this.$route.params.id}`)
                .then(response => {
                    this.empresa = response.data;
 
                    if (response.data.ficha == 'Redes Sociais') {
                        this.prefix = 'rs';
                        this.ficha = 'Redes Sociais';
                    }
                    if (response.data.ficha == 'Marketplace') {
                        this.prefix = 'mk';
                        this.ficha = 'Marketplace';
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            await axios
                .get(`/api/v1/produtos${this.prefix}`)
                .then(response => {
                    this.produtos = response.data.filter(
                        produto =>
                            produto.fk_id_atendimento_prod_mk ==
                            this.$route.params.id
                    );
                    this.legenda_produtos = legenda_produtos
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
