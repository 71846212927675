import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';

// axios.defaults.baseURL = 'http://127.0.0.1:8000';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    axios,
    render: h => h(App),
    beforeCreate() {
        this.$store.commit('initializeStore');
    },
}).$mount('#app');
