<template>
  <div class="control scltip">
    <slot></slot>
    <span>Para alterar o e-mail, contate um administrador</span>
  </div>
</template>

<script>
export default {
    name: 'TooltipEmail'
}
</script>

<style scoped>
div.scltip {
  border-bottom: 1px;
  text-decoration: none;
}
div.scltip:hover {
  cursor: help;
  position: relative;
}
div.scltip span {
  display: none;
}
div.scltip:hover span {
  border: 1px solid rgba(100, 72, 72, 0.2);
  background-color: rgba(255, 255, 0, 0.3);
  display: block;
  z-index: 100;
  left: 300px;
  position: absolute;
  top: 10px;
  text-decoration: none;
}
</style>