<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Ciclos</h1>

        <router-link
          class="button is-success"
          to="/dashboard/ciclos/iniciar-ciclo"
        >Iniciar ciclo</router-link>
      </div>
      <div class="column is-12">
        <v-data-table
          :headers="headers"
          :items="ciclos"
          item-key="id_ciclo"
          class="elevation-1"
          :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                    }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link :to="{
                                name: 'EditarCiclo',
                                params: { id: item.id_ciclo },
                            }">
              Editar
            </router-link>
          </template>
          <template v-slot:[`item.qt_rs`]="{ item }">
            {{ nonePara(item.qt_rs) }}
          </template>
          <template v-slot:[`item.qt_mk`]="{ item }">
            {{ nonePara(item.qt_mk) }}
          </template>
          <template v-slot:[`item.ic_concluido`]="{ item }">
            {{ binarioParaBool(item.ic_concluido) }}
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Ciclos',
    data() {
        return {
            ciclos: [],
            switch1: false,
            headers: [
                { text: 'Ciclo', value: 'id_ciclo' },
                { text: 'Dt. Entrada', value: 'dt_entrada' },
                { text: 'Qt. Redes Sociais', value: 'qt_rs' },
                { text: 'Qt. Marketplace', value: 'qt_mk' },
                { text: 'Status', value: 'ic_concluido' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
        };
    },
    mounted() {
        this.getCiclos();
    },
    methods: {
        nonePara(valor) {
            if (valor) {
                return valor;
            }
            return 0;
        },
        binarioParaBool(valor) {
            if (valor === 1) {
                return 'Concluido';
            }
            return 'Em andamento';
        },
        async getCiclos() {
            this.$store.commit('setIsLoading', true);

            axios
                .get('/api/v1/ciclos/')
                .then(response => {
                    this.ciclos = response.data.sort((a, b) => {
                        if (a.id_ciclo > b.id_ciclo) return -1
                        return 1
                    });
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
