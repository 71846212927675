<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Editar agência</h1>

        <button
          @click="deletarAgencia()"
          class="button is-danger"
        >
          Deletar agência
        </button>
      </div>

      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Ciclo triagem</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="agencia.fk_id_ciclo_triagem"
              />
            </div>
          </div>

          <div class="field">
            <label>Nome PO</label>
            <div class="control">
              <div class="select">
                <select v-model="agencia.fk_id_po">
                  <option
                    v-for="pessoa in pessoas"
                    v-bind:key="pessoa.id_pessoa"
                    v-bind:value="pessoa.id_pessoa"
                  >
                    {{ pessoa.nome }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label>Nome agência</label>
            <div class="control">
              <div class="select">
                <select v-model="agencia.agencia">
                  <option value="Mestra">Mestra</option>
                  <option value="Tropikalia">Tropikalia</option>
                  <option value="Papaya">Papaya</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="notification is-danger"
            v-if="erros.length"
          >
            <p
              v-for="error in erros"
              v-bind:key="error"
            >
              {{ error }}
            </p>
          </div>

          <div class="field">
            <button class="button is-success">
              Atualizar Agência
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
export default {
    name: 'EditarAgencia',
    data() {
        return {
            erros: [],
            agencia: {},
            pessoas: [],
        };
    },
    mounted() {
        this.getPessoas();
        this.getAgencia();
    },
    methods: {
        async getPessoas() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get('/api/v1/pessoas/')
                .then(response => {
                    this.pessoas = response.data.filter(p => p.funcao == 'PO');
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async getAgencia() {
            this.$store.commit('setIsLoading', true);

            const IDagencia = this.$route.params.id;

            await axios
                .get(`/api/v1/agencias/${IDagencia}`)
                .then(response => {
                    this.agencia = response.data;
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async deletarAgencia() {
            var resultado = confirm('Deseja mesmo deletar a agência?');
            if (!resultado) {
                return;
            }

            this.$store.commit('setIsLoading', true);

            const IDagencia = this.$route.params.id;
            await axios
                .get(`/api/v1/agencias/${IDagencia}`)
                .then(response => {
                    this.agencia = response.data;
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async submitForm() {
            this.erros = [];

            if (
                this.fk_id_ciclo_triagem === 0 ||
                this.fk_id_po === '' ||
                this.agencia === ''
            ) {
                this.erros.push('Alguns campos estão vazios');
            }

            if (!this.erros.length) {
                this.$store.commit('setIsLoading', true);

                const IDagencia = this.$route.params.id;

                await axios
                    .patch(`/api/v1/agencias/${IDagencia}/`, this.agencia)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    });

                const info_empresas = {
                    id_agencia: IDagencia,
                    ciclo_triagem: this.agencia.fk_id_ciclo_triagem,
                    id_po: this.agencia.fk_id_po,
                    agencia: this.agencia.agencia,
                };

                await axios
                    .put(`/api/v1/agencias/atualizar_agencias_das_empresas/1/`, info_empresas)
                    .then(response => {
                        console.log(response)
                        toast({
                            message: 'A agência foi atualizada',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        });
                        this.$router.push('/dashboard/agencias');
                    })
                    .catch(error => {
                        console.error(error);
                    });

                this.$store.commit('setIsLoading', false);
            }
        },
    },
};
</script>
