<template>
  <div :class="modalAtivo">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{titulo}}</p>
        <button
          class="delete"
          aria-label="close"
          @click="fecharModal"
        ></button>
      </header>
      <section class="modal-card-body">
        <slot />
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-success"
          @click="emitirClick"
        >{{textoBotaoSalvar}}</button>
        <button
          class="button"
          @click="fecharModal"
        >{{textoBotaoCancelar}}</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
    name: "CustomModal",
    props: {
        titulo: {
            type: String
        },
        textoBotaoSalvar: {
            type: String,
            default: 'Salvar'
        },
        textoBotaoCancelar: {
            type: String,
            default: 'Cancelar'
        },
        value: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        fecharModal() {
            this.$emit('input', false)
        },
        emitirClick() {
            this.$emit('click')
            this.fecharModal()
        }
    },
    computed: {
        modalAtivo() {
            return 'modal' + (this.value ? ' is-active' : '')
        }
    }
}
</script>

<style>

</style>