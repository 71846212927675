<template>
  <button
    class="button is-info ml-4"
    type="button"
    @click="voltarPagina"
  >Voltar</button>
</template>

<script>
    export default {
        name: 'BotaoVoltar',
        methods: {
            voltarPagina() {
                this.$router.go(-1)
            }
        }
    }
</script>
