<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Criar ciclo</h1>
      </div>

      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Ciclo</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="id_ciclo"
              />
            </div>
          </div>

          <div class="field">
            <label>Qt. Redes Sociais</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="qt_rs"
              />
            </div>
          </div>

          <div class="field">
            <label>Qt. Marketplace</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="qt_mk"
              />
            </div>
          </div>

          <div class="field">
            <label>Dt. entrada</label>
            <div class="control">
              <input
                type="date"
                class="input"
                v-model="dt_entrada"
              />
            </div>
          </div>

          <div class="field">
            <button
              class="button is-success"
              type="submit"
            >Criar ciclo</button>
            <botao-voltar />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import BotaoVoltar from '../../../components/BotaoVoltar'
import { toast } from 'bulma-toast';

export default {
    name: 'IniciarCiclo',
    components: {BotaoVoltar},
    data() {
        return {
            id_ciclo: '',
            qt_rs: 0,
            qt_mk: 0,
            dt_entrada: '',
        };
    },
    methods: {
        async submitForm() {
            this.$store.commit('setIsLoading', true);

            const ciclo = {
                id_ciclo: this.id_ciclo,
                dt_entrada: this.dt_entrada,
                qt_rs: this.qt_rs,
                qt_mk: this.qt_mk,
                ic_concluido: 0,
            };

            console.log(ciclo)
            await axios
                .post('/api/v1/ciclos/', ciclo)
                .then(resposnse => {
                    toast({
                        message: 'O ciclo foi criado',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                    this.$router.push('/dashboard/ciclos')
                })
                .catch(error => {
                    console.error(error);
                });


            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
