<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Empresas {{ nome_fantasia }}</h1>
      </div>
      <hr />
      <form>
        <div class="field has-addons">
          <div class="control mx-2">
            <input
              type="text"
              class="input"
              v-model="search"
              placeholder="Filtro"
            />
          </div>
          <div class="control mx-2">
            <input
              type="text"
              class="input"
              v-model="filtro_ciclo"
              placeholder="Ciclo"
            />
          </div>
          <div class="select mx-2">
            <select v-model="filtro_ficha">
              <option
                select
                value=""
              >Selecionar Ficha</option>
              <option value="Marketplace">Marketplace</option>
              <option value="Redes Sociais">Redes Sociais</option>
            </select>
          </div>
          <div
            class="button mx-2 is-info"
            @click="limparFiltro"
          >
            Limpar filtro
          </div>
        </div>
      </form>
    </div>

    <div class="column is-12">
      <v-data-table
        :headers="headers"
        :items="empresas"
        :search="search"
        item-key="id_atendimento"
        class="elevation-1"
        :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <router-link :to="{
                            name: 'Empresa',
                            params: { id: item.id_atendimento },
                        }">
            <v-icon medium>mdi-plus</v-icon>
          </router-link>
          <router-link
            v-if="$store.getters.esAdministrador"
            :to="{
                            name: 'ConfigurarEmpresa',
                            params: { id: item.id_atendimento },
                        }"
          >
            <v-icon medium>mdi-cog</v-icon>
          </router-link>
        </template>
        <template v-slot:[`item.fk_id_po`]="{ item }">
          {{ pegarPessoa(item.fk_id_po) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Empresas',
    data() {
        return {
            filtro_ciclo: '',
            filtro_ficha: '',
            search: '',
            nome_fantasia: '',
            currentPage: 1,
            empresas: [],
            pessoas: [],
            items: ['Marketplace', 'Redes Sociais'],
            headers: [
                {
                    text: 'ID Atendimento',
                    align: 'start',
                    value: 'id_atendimento',
                },
                { text: 'Nome sebrae', value: 'nome_fantasia' },
                { text: 'Nome fantasia', value: 'nome_fantasia_real' },
                {
                    text: 'Tipo de ficha',
                    value: 'ficha',
                    filter: this.filtroFicha,
                },
                {
                    text: 'Ciclo',
                    value: 'ciclo_triagem',
                    filter: this.filtroCiclo,
                },
                { text: 'PO', value: 'nome' },
                { text: 'Status', value: 'status' },
                { text: 'Número demanda', value: 'numero_demanda' },
                { text: 'Agência', value: 'agencia' },
                { text: 'Mais informacoes', value: 'actions', sortable: false },
            ],
        };
    },
    mounted() {
        this.getEmpresas();
    },
    methods: {
        async getEmpresas() {
            this.$store.commit('setIsLoading', true);
            await axios
                .get(`/api/v1/empresas/get_empresas/1/`)
                .then(response => {
                    this.empresas = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

            await axios
                .get('/api/v1/pessoas/')
                .then(response => {
                    this.pessoas = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
            console.log(this.empresas[0])

            this.$store.commit('setIsLoading', false);
        },
        pegarPessoa(id_pessoa) {
            var pessoa = this.pessoas.filter(p => p.id_pessoa == id_pessoa);
            if (pessoa[0] === undefined) {
                return;
            }
            return pessoa[0].nome;
        },
        filtroCiclo(value) {
            if (!this.filtro_ciclo) {
                return true;
            } else {
                return this.filtro_ciclo == value;
            }
        },
        filtroFicha(value) {
            if (!this.filtro_ficha) {
                return true;
            } else {
                return this.filtro_ficha == value;
            }
        },
        limparFiltro() {
            this.filtro_ciclo = ''
            this.filtro_ficha = ''
            this.search = ''
        }
    },
};
</script>
