<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">
          {{ nome_fantasia }} - {{ empresa.id_atendimento }}
        </h1>
        <div class="buttons">
          <router-link
            class="button is-info"
            :to="{
                            name: 'EditarEmpresa',
                            params: { id: empresa.id_atendimento },
                        }"
          >Editar</router-link>

          <router-link
            class="button is-info"
            :to="{
                            name: 'Empresas',
                        }"
          >Voltar</router-link>
          <router-link
            class="button is-info"
            :to="{
                            name: 'Produtos',
                            params: { id: empresa.id_atendimento },
                        }"
          >Produtos</router-link>
        </div>
      </div>

      <div class="column is-6">
        <div class="box">
          <p><strong>Tipo ficha: </strong>{{ empresa.ficha }}</p>
          <p>
            <strong>Escritório regional: </strong>{{ empresa.er }}
          </p>
          <p>
            <strong>Contato: </strong>{{ empresa.nome_contato }}
          </p>
          <p>
            <strong>Telefone: </strong>{{ empresa.telefone_contato }}
          </p>
          <p>
            <strong>Email: </strong>{{ empresa.email_contato }}
          </p>
          <p>
            <strong>Num demanda: </strong>{{ empresa.numero_demanda }}
          </p>
          <p>
            <strong>Status: </strong>{{ empresa.status }}
          </p>
        </div>
      </div>
      <div class="column is-6">
        <div class="box">
          <p><strong>Ciclo Triagem: </strong>{{ empresa.ciclo_triagem }}</p>
          <p v-if="empresa.id_board_triagem">
            <strong>Board Triagem: </strong><a
              class="button is-info is-small"
              v-bind:href="'https://trello.com/b/'+ empresa.id_board_triagem"
            >Ir para o board</a>
          </p>
          <p v-if="empresa.ciclo_atendimento">
            <strong>Ciclo Atendimento: </strong>{{ empresa.ciclo_atendimento }}
          </p>
          <p v-if="empresa.id_board_atendimento">
            <strong>Board Atendimento: </strong><a
              class="button is-info is-small"
              v-bind:href="'https://trello.com/b/'+ empresa.id_board_atendimento"
            >Ir para o board</a>
          </p>
          <p v-if="empresa.fk_id_po">
            <strong>PO: </strong>{{ empresa.fk_id_po }}
          </p>
          <p v-if="empresa.fk_id_po">
            <strong>Agencia: </strong>{{ empresa.agencia }}
          </p>
        </div>
      </div>
      <div class="column is-12">
        <div class="box">
          <div
            v-for="info in legenda_info_adicional[prefix]"
            v-bind:key="info.legenda"
          >
            <p v-if="info_adicional[info.valor]">
              <strong>{{ info.legenda }}: </strong>
              {{ info_adicional[info.valor] }}
            </p>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <button
          v-if="$store.getters.esAdministrador"
          class="button is-danger"
        >Apagar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { legenda_empresas } from '../../settings.js'
export default {
    name: 'Empresa',
    data() {
        return {
            empresa: {},
            info_adicional: {},
            prefix: '',
            nome_fantasia: '',
            legenda_info_adicional: {},
            ficha: '',
        };
    },
    mounted() {
        this.getEmpresa();
        this.getPessoas();
    },
    methods: {
        async getEmpresa() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get(`/api/v1/empresas/${this.$route.params.id}`)
                .then(response => {
                    this.empresa = response.data;

                    if (response.data.ficha == 'Redes Sociais') {
                        this.prefix = 'rs';
                        this.ficha = 'Redes Sociais';
                    }
                    if (response.data.ficha == 'Marketplace') {
                        this.prefix = 'mk';
                        this.ficha = 'Marketplace';
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            await axios
                .get(`/api/v1/empresas${this.prefix}/${this.$route.params.id}`)
                .then(response => {
                    this.info_adicional = response.data;
                    console.log('this.info_adicional')
                    console.log(this.info_adicional)
                    this.legenda_info_adicional = legenda_empresas
                    console.log(this.legenda_info_adicional[this.prefix])
                    this.nome_fantasia = response.data[`nome_fantasia_${this.prefix}`]
                })
                .catch(error => {
                    console.log(error);
                });
            this.$store.commit('setIsLoading', false);
        },
        async getPessoas() {
            this.$store.commit('setIsLoading', true);

            axios
                .get('/api/v1/pessoas/')
                .then(response => {
                    this.pessoas = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        pegarPessoa(id_pessoa) {
            var pessoa = this.pessoas.filter(p => p.id_pessoa == id_pessoa);
            if (pessoa[0] === undefined) {return}
            return pessoa[0].nome
        }
    },
};
</script>
