<template>
    <div class="container">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Entrar</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input
                                type="email"
                                name="email"
                                class="input"
                                v-model="username"
                            />
                        </div>
                    </div>

                    <div class="field">
                        <label>Senha</label>
                        <div class="control">
                            <input
                                type="password"
                                name="senha"
                                class="input"
                                v-model="senha"
                            />
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">
                            {{ error }}
                        </p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Entrar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'LogIn',
    data() {
        return {
            username: '',
            senha: '',
            errors: [],
        };
    },
    methods: {
        async submitForm() {
            if (this.username === '') {
                this.errors.push('O email está em branco');
            }

            if (this.senha.length < 8) {
                this.errors.push('A senha é muito pequena');
            }

            if (!this.errors.length) {
                this.$store.commit('setIsLoading', true);

                axios.defaults.headers.common['Authorization'] = '';
                localStorage.removeItem('token');

                const formData = {
                    username: this.username,
                    password: this.senha,
                };

                axios
                    .post('/api/v1/token/login/', formData)
                    .then(response => {
                        const token = response.data.auth_token;

                        this.$store.commit('setToken', token);

                        axios.defaults.headers.common['Authorization'] =
                            'Token ' + token;

                        localStorage.setItem('token', token);
                        this.$store.commit('inicializarUsuario')

                        console.log(token);

                        this.$router.push('/dashboard/minha-conta');
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(
                                    `${property}: ${error.response.data[property]}`
                                );
                            }
                        } else if (error.message) {
                            this.errors.push(
                                'Something went wrong. Please try again!'
                            );
                        }
                    });
            }
            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
