<template>
  <div class="container">
    <div class="column is-multiline">
      <button
        class="button is-danger"
        @click="logout()"
      >Sair</button>
    </div>
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Meus dados</h1>
      </div>
      <div class="column is-12">
        <div
          class="field"
          v-if="!pessoa.id_pessoa"
        >
          <label>E-mail</label>
          <tooltip-email>
            <input
              type="email"
              class="input"
              v-model="$store.getters.user.username"
              disabled
            />
          </tooltip-email>
          <div>
            <p>Parece que você não possui uma conta completa, apenas o cadastro básico. Para ter uma conta completa, peça para algum administrador para que te cadastre</p>
          </div>
          <button
            class="button is-success"
            type="button"
            style="margin-left: 10px;"
            @click="abrirModal"
          >Trocar senha</button>
        </div>
        <form
          @submit.prevent="submitForm()"
          v-else
        >
          <div class="field">
            <label>Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="pessoa.nome"
              />
            </div>
          </div>

          <div class="field">
            <label>Primeiro Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="pessoa.primeiro_nome"
              />
            </div>
          </div>

          <div class="field">
            <label>E-mail</label>
            <tooltip-email>
              <input
                type="email"
                class="input"
                v-model="pessoa.email"
                disabled
              />
            </tooltip-email>
          </div>

          <div class="field">
            <label>Telefone</label>
            <div class="control">
              <input-telefone v-model="pessoa.telefone" />
            </div>
          </div>

          <div class="field">
            <label>Função</label>
            <div class="control">
              <div class="select">
                <select v-model="pessoa.funcao">
                  <option value="PO">PO</option>
                  <option value="HEAD">HEAD</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label>Situação</label>
            <div class="control">
              <div class="select">
                <select v-model="pessoa.ativo">
                  <option :value="true">Ativo</option>
                  <option :value="false">Inativo</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <button
              class="button is-success"
              type="submit"
            >Editar dados</button>
            <button
              class="button is-success"
              type="button"
              style="margin-left: 10px;"
              @click="abrirModal"
            >Trocar senha</button>
            <botao-voltar />
          </div>
        </form>
      </div>
    </div>
    <custom-modal
      titulo="Trocar senha"
      v-model="modalEstaAberto"
      @click="trocarSenha"
    >
      <div class="field">
        <label>Nova senha</label>
        <div class="control">
          <input
            type="password"
            class="input"
            v-model="novaSenha"
          />
        </div>
      </div>
      <div class="field">
        <label>Repetir nova senha</label>
        <div class="control">
          <input
            type="password"
            class="input"
            v-model="novaSenhaRepetida"
          />
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import axios from 'axios'

import { toast } from 'bulma-toast'
import BotaoVoltar from '../../../components/BotaoVoltar'
import InputTelefone from '../../../components/InputTelefone'
import TooltipEmail from '../../../components/TooltipEmail'
import CustomModal from '../../../components/CustomModal'

export default {
    components: {
        BotaoVoltar,
        InputTelefone,
        TooltipEmail,
        CustomModal
    },
    name: 'MinhaConta',
    data() {
        return {
            notification: false,
            pessoa: {},
            modalEstaAberto: false,
            novaSenha: '',
            novaSenhaRepetida: '',
        }
    },
    mounted() {
        this.getPessoa()
    },
    methods: {
        abrirModal() {
            this.modalEstaAberto = true
        },
        async trocarSenha() {
            if (!this.novaSenha || this.novaSenha.length < 8) {
                toast({
                    message: `A senha deve ter no mínimo 8 caracteres`,
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
                return
            }
            if (this.novaSenha !== this.novaSenhaRepetida) {
              toast({
                message: `As senhas precisam ser iguais`,
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
                return
            }
            try {
                const response = await axios.post('/api/v1/usuarios/senha', {nova_senha: this.novaSenha})
                toast({
                message: 'Sucesso ao trocar a senha!',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
            } catch (error) {
                let mensagem = 'Ocorreu um erro ao trocar a senha'
                if (error.response.data) {
                    mensagem = error.response.data.erro || mensagem
                }
                toast({
                message: mensagem,
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
            }
        },
        async logout() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post('/api/v1/token/logout/')
                .then(response => {
                    console.log('Logged out')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })

            axios.defaults.headers.common['Authorization'] = ''

            localStorage.removeItem('token')
            localStorage.removeItem('id')
            localStorage.removeItem('username')
            localStorage.removeItem('funcao')

            this.$store.commit('removeToken')
            this.$store.commit('setUser', {})

            this.$router.push('/')

            this.$store.commit('setIsLoading', false)
        },
        async getPessoa() {
            this.$store.commit('setIsLoading', true)

            const IDpessoa = this.$store.getters.user.id

            try {
                const response = await axios
                    .get('/api/v1/pessoas/email')
                this.pessoa = response.data
                this.pessoa.ativo = Boolean(this.pessoa.ativo)
            } catch (error) {
                console.log(error)
                if (error.response.status == 404) return
                toast({
                    message: `Ocorreu um erro ao buscar os dados`,
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
            } finally {
                this.$store.commit('setIsLoading', false)
            }
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const IDpessoa = this.pessoa.id_pessoa

            await axios
                .patch(`/api/v1/pessoas/${IDpessoa}/`, this.pessoa)
                .then(response => {
                    toast({
                        message: `Seus dados foram atualizados`,
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 4000,
                        position: 'bottom-right',
                    })

                    this.$router.push(`/dashboard/pessoas`)
                })
                .catch(error => {
                    toast({
                        message: `Ocorreu um erro ao atualizar os dados`,
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 4000,
                        position: 'bottom-right',
                    })
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
    },
}
</script>
