<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Produto</h1>
      </div>
      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div
            v-for="info in legenda_produtos[prefix]"
            v-bind:key="info.legenda"
            class="field"
          >
            <label>{{info.legenda}}</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="produto[info.valor]"
              />
            </div>
          </div>
          <div class="field">
            <button class="button is-success">Editar produto</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { legenda_produtos } from '../../settings.js'
    export default {
        name: "Produto",
        data() {
            return{
                produto: {},
                prefix: '',
                legenda_produtos: {},
            }
        },
        mounted() {
            this.getProduto();
        },
        methods: {
            async getProduto() {
                this.$store.commit('setIsLoading', true);

                await axios
                    .get(`/api/v1/empresas/${this.$route.params.id}`)
                    .then(response => {
                        this.empresa = response.data;
    
                        if (response.data.ficha == 'Redes Sociais') {
                            this.prefix = 'rs';
                            this.ficha = 'Redes Sociais';
                        }
                        if (response.data.ficha == 'Marketplace') {
                            this.prefix = 'mk';
                            this.ficha = 'Marketplace';
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                await axios
                    .get(`/api/v1/produtos${this.prefix}/${this.$route.params.produto}`)
                    .then(response => {
                        this.produto = response.data;
                        this.legenda_produtos = legenda_produtos
                })
                    .catch(error => {
                        console.log(error);
                });
            
                this.$store.commit('setIsLoading', false);
            },
            async submitForm() {
            this.$store.commit('setIsLoading', true);

            const IDproduto = this.$route.params.produto;

            console.log(this.produto)
            await axios
                .patch(`/api/v1/produtos${this.prefix}/${IDproduto}/`, this.produto)
                .then(response => {
                    toast({
                        message: 'O produto foi atualizado',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                    console.log(response)

                    this.$router.push(`/dashboard/empresas/${this.$route.params.id}/produtos`);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        },
    
}
</script>