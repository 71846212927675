<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Adicionar Agência</h1>
      </div>

      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Ciclo triagem</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="fk_id_ciclo_triagem"
              />
            </div>
          </div>

          <div class="field">
            <label>Nome PO</label>
            <div class="control">
              <div class="select">
                <select v-model="fk_id_po">
                  <option
                    value=""
                    selected
                  >Selecione o PO</option>
                  <option
                    v-for="pessoa in pessoas"
                    v-bind:key="pessoa.id_pessoa"
                    v-bind:value="pessoa.id_pessoa"
                  >
                    {{ pessoa.nome }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label>Nome agência</label>
            <div class="control">
              <div class="select">
                <select v-model="agencia">
                  <option value="Mestra">Mestra</option>
                  <option value="Tropikalia">Tropikalia</option>
                  <option value="Papaya">Papaya</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="notification is-danger"
            v-if="erros.length"
          >
            <p
              v-for="error in erros"
              v-bind:key="error"
            >
              {{ error }}
            </p>
          </div>

          <div class="field">
            <button class="button is-success">Adicionar Agência</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { toast } from 'bulma-toast';

export default {
    name: 'AdicionarAgencia',
    data() {
        return {
            pessoas: [],
            fk_id_ciclo_triagem: 0,
            fk_id_po: '',
            agencia: '',
            erros: [],
        };
    },
    mounted() {
        this.getPessoas();
    },
    methods: {
        async getPessoas() {
            this.$store.commit('setIsLoading', true);

            axios
                .get('/api/v1/pessoas/')
                .then(response => {
                    this.pessoas = response.data.filter(p => p.funcao == 'PO');
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async submitForm() {
            this.erros = [];

            if (
                this.fk_id_ciclo_triagem === 0 ||
                this.fk_id_po === '' ||
                this.agencia === ''
            ) {
                this.erros.push('Alguns campos estão vazios');
            }

            if (!this.erros.length) {
                this.$store.commit('setIsLoading', true);

                const agencia = {
                    fk_id_ciclo_triagem: this.fk_id_ciclo_triagem,
                    fk_id_po: this.fk_id_po,
                    agencia: this.agencia,
                };

                await axios
                    .post('/api/v1/agencias/', agencia)
                    .then(resposnse => {
                        toast({
                            message: 'A agência foi criada',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        });
                        this.$router.push('/dashboard/agencias');
                    })
                    .catch(error => {
                        console.error(error);
                    });

                this.$store.commit('setIsLoading', false);
            }
        },
    },
};
</script>
