<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Pessoas</h1>

        <router-link
          class="button is-success"
          to="/dashboard/pessoas/adicionar"
        >Adicionar pessoa</router-link>
      </div>
      <div class="column is-12">
        <v-data-table
          :headers="headers"
          :items="pessoas"
          item-key="id_pessoa"
          class="elevation-1"
          :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                    }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link :to="{
                                name: 'Pessoa',
                                params: { id: item.id_pessoa },
                            }">
              Editar
            </router-link>
          </template>
          <template v-slot:[`item.is_staff`]="{ item }">
            <v-simple-checkbox
              @input="alterarAdmin($event, item)"
              v-ripple="false"
              :disabled="item.disabled"
              color="black"
              v-model="item.is_staff"
            >
            </v-simple-checkbox>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Pessoas',
    data() {
        return {
            pessoas: [],
            headers_padrao: [
                { text: 'Nome', value: 'nome' },
                { text: 'Email', value: 'email' },
                { text: 'Telefone', value: 'telefone' },
                { text: 'Funcao', value: 'funcao' },
                { text: 'Primeiro Nome', value: 'primeiro_nome'},
                { text: 'Situação', value: 'ativo'},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
        };
    },
    mounted() {
        this.getPessoas();
    },
    methods: {
        async getPessoas() {
            this.$store.commit('setIsLoading', true);

            axios
                .get('/api/v1/pessoas/todas')
                .then(response => {
                    this.pessoas = response.data.map(pessoa => {
                        pessoa.ativo = pessoa.ativo ? 'Ativo' : 'Inativo'
                        pessoa.disabled = pessoa.is_staff === null ? true : false
                        return pessoa
                    });
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
      async alterarAdmin(input, pessoa) {
        await axios.patch(`/api/v1/usuarios/${pessoa.id_usuario}/`, {
          is_staff: input
        })
        //pessoa.is_staff = input
      }
    },
    computed: {
      headers() {
        const headers = this.headers_padrao
        if (this.$store.getters.esAdministrador) {
          headers.push({ text: 'Administrador', value: 'is_staff', sortable: false })
        }
        return headers
      }
    }
};
</script>
