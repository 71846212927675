var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"column is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"title"},[_vm._v("Pessoas")]),_c('router-link',{staticClass:"button is-success",attrs:{"to":"/dashboard/pessoas/adicionar"}},[_vm._v("Adicionar pessoa")])],1),_c('div',{staticClass:"column is-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pessoas,"item-key":"id_pessoa","footer-props":{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-minus',
                      nextIcon: 'mdi-plus',
                  }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                              name: 'Pessoa',
                              params: { id: item.id_pessoa },
                          }}},[_vm._v(" Editar ")])]}},{key:"item.is_staff",fn:function(ref){
                          var item = ref.item;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"disabled":item.disabled,"color":"black"},on:{"input":function($event){return _vm.alterarAdmin($event, item)}},model:{value:(item.is_staff),callback:function ($$v) {_vm.$set(item, "is_staff", $$v)},expression:"item.is_staff"}})]}}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }