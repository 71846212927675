<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">
          Configurar Empresa: {{ empresa.nome_fantasia }} -
          {{ empresa.id_atendimento }}
        </h1>
      </div>

      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Tipo ficha: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.ficha"
              />
            </div>
          </div>
          <div class="field">
            <label>Ciclo: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.ciclo_triagem"
              />
            </div>
          </div>
          <div class="field">
            <label>Agencia: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.agencia"
              />
            </div>
          </div>
          <div class="field">
            <label>Status</label>
            <div class="control">
              <div class="select">
                <select v-model="empresa.status">
                  <option value=""></option>
                  <option value="CANCELADO">CANCELADO</option>
                  <option value="ADIADO">ADIADO</option>
                  <option value="CONCLUIDO">CONCLUIDO</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Status</label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="empresa.observacao_status"
              ></textarea>
            </div>
          </div>
          <div class="field">
            <label>Nome PO</label>
            <div class="control">
              <div class="select">
                <select v-model="empresa.fk_id_po">
                  <option
                    v-for="pessoa in pessoas"
                    v-bind:key="pessoa.id_pessoa"
                    v-bind:value="pessoa.id_pessoa"
                  >
                    {{ pessoa.nome }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Numero demanda: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.numero_demanda"
              />
            </div>
          </div>
          <div class="field">
            <button class="button is-success">
              Atualizar empresa
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
export default {
    name: 'ConfigurarEmpresa',
    data() {
        return {
            empresa: {},
            pessoas: [],
            prefix: '',
            ficha: '',
        };
    },
    mounted() {
        this.getEmpresa();
    },
    methods: {
        async getEmpresa() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get(`/api/v1/empresas/${this.$route.params.id}`)
                .then(response => {
                    this.empresa = response.data;

                    if (response.data.ficha == 'Redes Sociais') {
                        this.prefix = 'rs';
                        this.ficha = 'Redes Sociais';
                    }
                    if (response.data.ficha == 'Marketplace') {
                        this.prefix = 'mk';
                        this.ficha = 'Marketplace';
                    }
                })
                .catch(error => {
                    console.log(error);
                });


            await axios
                .get('/api/v1/pessoas/')
                .then(response => {
                    this.pessoas = response.data.filter(p => p.funcao == 'PO');
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true);

            await axios
                .patch(
                    `/api/v1/empresas/${this.empresa.id_atendimento}/`,
                    this.empresa
                )
                .then(response => {
                    console.log(response.data);
                    toast({
                        message: 'A empresa foi atualizada!',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });

                    this.$router.push(`/dashboard/empresas`);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
