<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">
          Editar Empresa: {{ empresa.nome_fantasia }} - {{ empresa.id_atendimento }}
        </h1>
      </div>

      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Tipo ficha: </label>
            <div class="control">
              <input
                disabled
                type="text"
                class="input"
                v-model="empresa.ficha"
              >
            </div>
          </div>
          <div class="field">
            <label>Ciclo: </label>
            <div class="control">
              <input
                disabled
                type="text"
                class="input"
                v-model="empresa.ciclo_triagem"
              >
            </div>
          </div>
          <div class="field">
            <label>Escritório regional: </label>
            <div class="control">
              <input
                disabled
                type="text"
                class="input"
                v-model="empresa.er"
              >
            </div>
          </div>
          <div class="field">
            <label>Nome contato: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.nome_contato"
              >
            </div>
          </div>
          <div class="field">
            <label>Telefone contato: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.telefone_contato"
              >
            </div>
          </div>
          <div class="field">
            <label>Email contato: </label>
            <div class="control">
              <input
                type="email"
                class="input"
                v-model="empresa.email_contato"
              >
            </div>
          </div>
          <div class="field">
            <label>Numero demanda: </label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="empresa.numero_demanda"
              >
            </div>
          </div>
          <div
            class="field"
            v-for="info in legenda_info_adicional[prefix]"
            v-bind:key="info.legenda"
          >
            <label>{{ info.legenda }}</label>
            <div class="control">
              <input
                :type="info.tipo"
                class="input"
                v-model="info_adicional[info.valor]"
              />
            </div>
          </div>

          <div class="field">
            <button class="button is-success">Atualizar empresa</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { legenda_empresas } from '../../settings.js'
import { toast } from 'bulma-toast';
export default {
    name: 'EditarEmpresa',
    data() {
        return {
            empresa: {},
            info_adicional: {},
            legenda_info_adicional: [],
            prefix: '',
            ficha: '',
        };
    },
    mounted() {
        this.getEmpresa();
    },
    methods: {
        async getEmpresa() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get(`/api/v1/empresas/${this.$route.params.id}`)
                .then(response => {
                    this.empresa = response.data;

                    if (response.data.ficha == 'Redes Sociais') {
                        this.prefix = 'rs';
                        this.ficha = 'Redes Sociais';
                    }
                    if (response.data.ficha == 'Marketplace') {
                        this.prefix = 'mk';
                        this.ficha = 'Marketplace';
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            await axios
                .get(`/api/v1/empresas${this.prefix}/${this.$route.params.id}`)
                .then(response => {
                    this.info_adicional = response.data;
                    this.legenda_info_adicional = legenda_empresas
                    console.log(this.legenda_info_adicional)
                })
                .catch(error => {
                    console.log(error);
                });
            this.$store.commit('setIsLoading', false);
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            await axios
                .patch(`/api/v1/empresas${this.prefix}/${this.empresa.id_atendimento}/`, this.info_adicional)
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                })

            await axios
                .patch(`/api/v1/empresas/${this.empresa.id_atendimento}/`, this.empresa)
                .then(response => {
                    toast({
                        message: 'A empresa foi atualizada!',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });

                    this.$router.push(`/dashboard/empresas`);
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        }
    },
};
</script>
