<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Agência</h1>
      </div>
      <hr />
      <form>
        <div class="field has-addons">
          <div class="select mx-2">
            <select v-model="filtro_pessoa">
              <option
                select
                value=""
              >Selecionar Pessoa</option>
              <option
                v-for="pessoa in pessoas"
                v-bind:key="pessoa.id_pessoa"
                :value="pessoa.nome"
              >{{ pessoa.nome }}</option>
            </select>
          </div>
          <div class="select mx-2">
            <select v-model="filtro_agencia">
              <option
                select
                value=""
              >Selecionar Agencia</option>
              <option value="A distribuir">A distribuir</option>
              <option value="Mestra">Mestra</option>
              <option value="Papaya">Papaya</option>
              <option value="Tropikalia">Tropikalia</option>
            </select>
          </div>
          <div class="select mx-2">
            <select v-model="filtro_ficha">
              <option
                select
                value=""
              >Selecionar Ficha</option>
              <option value="Marketplace">Marketplace</option>
              <option value="Redes Sociais">Redes Sociais</option>
            </select>
          </div>
          <div
            class="button mx-2 is-info"
            @click="distribuir"
          >
            Agências à distribuir
          </div>
          <!-- <div class="button mx-2 is-info" @click="limparFiltro">
                        Limpar filtro
                    </div> -->
          <router-link
            class="button is-info mx-2"
            to="/dashboard/agencias/adicionar-agencia"
          >Adicionar agência</router-link>
        </div>
      </form>
      <div class="column is-12">
        <v-data-table
          :headers="headers"
          :items="agencias"
          class="elevation-1"
          :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                    }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link :to="{
                                name: 'EditarAgencia',
                                params: { id: item.id_agencia_ciclo_po },
                            }">
              Editar
            </router-link>
          </template>
          <template v-slot:[`item.nome_agencia`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.nome_agencia"
              large
              persistent
              @save="save(props)"
              @cancel="cancel"
            >
              {{ props.item.nome_agencia }}
              <template v-slot:input>
                <div class="select">
                  <select v-model="props.item.nome_agencia">
                    <option value="">A distribuir</option>
                    <option value="Tropikalia">Tropikalia</option>
                    <option value="Mestra">Mestra</option>
                    <option value="Papaya">Papaya</option>
                  </select>
                </div>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
export default {
    name: 'Agencias',
    data() {
        return {
            agencias: [],
            headers: [
                { text: 'Ciclo Triagem', value: 'ciclo_triagem' },
                { text: 'Nome PO', value: 'nome', filter: this.filtroPessoa },
                {
                    text: 'Nome agência',
                    value: 'nome_agencia',
                    filter: this.filtroAgencia,
                },
                {
                    text: 'Tipo Ficha',
                    value: 'ficha',
                    filter: this.filtroFicha,
                },
                { text: 'Quantidade', value: 'quantidade_fichas' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            pessoas: [],
            filtro_pessoa: '',
            filtro_agencia: '',
            filtro_ficha: '',
        };
    },
    mounted() {
        this.getAgencias();
        this.getPessoas();
    },
    methods: {
        async getAgencias() {
            this.$store.commit('setIsLoading', true);

            axios
                .get('/api/v1/agencias/get_agencias/1/')
                .then(response => {
                    this.agencias = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        async getPessoas() {
            this.$store.commit('setIsLoading', true);

            await axios
                .get('/api/v1/pessoas/')
                .then(response => {
                    this.pessoas = response.data.filter(p => p.funcao == 'PO');
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
        pegarPessoa(id_pessoa) {
            var pessoa = this.pessoas.filter(p => p.id_pessoa == id_pessoa);
            if (pessoa[0] === undefined) {
                return;
            }
            return pessoa[0].nome;
        },
        pegarIdPessoa(nome) {
            var pessoa = this.pessoas.filter(p => p.nome == nome);
            if (pessoa[0] === undefined) {
                return;
            }
            return pessoa[0].id_pessoa;
        },
        filtroPessoa(value) {
            if (!this.filtro_pessoa) {
                return true;
            } else {
                return this.filtro_pessoa == value;
            }
        },
        filtroFicha(value) {
            if (!this.filtro_ficha) {
                return true;
            } else {
                return this.filtro_ficha == value;
            }
        },
        filtroAgencia(value) {
            if (!this.filtro_agencia) {
                return true;
            } else {
                return this.filtro_agencia == value;
            }
        },
        limparFiltro() {
            this.filtro_pessoa = '';
            this.filtro_ficha = '';
            this.filtro_agencia = '';
        },
        async distribuir() {
            this.$store.commit('setIsLoading', true);

            await axios
                .post('/api/v1/agencias/agencias_a_distribuir/1/')
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                });

            this.getAgencias();

            this.$store.commit('setIsLoading', false);
        },
        async save(props) {
            this.$store.commit('setIsLoading', true);
            const agencia = props.item;
            console.log(agencia)
            const info_empresas = {
                id_agencia: agencia.id_agencia_ciclo_po,
                ciclo_triagem: agencia.ciclo_triagem,
                id_po: this.pegarIdPessoa(agencia.nome),
                agencia: agencia.nome_agencia,
            };

            console.log(info_empresas);

            await axios
                .put(
                    `/api/v1/agencias/atualizar_agencias_das_empresas/1/`,
                    info_empresas
                )
                .then(response => {
                    console.log(response);
                    toast({
                        message: 'A agência foi atualizada',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                })
                .catch(error => {
                    console.error(error);
                });
            this.$store.commit('setIsLoading', false);
        },
        cancel() {},
    },
};
</script>
