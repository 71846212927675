<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Editar Pessoa {{ pessoa.id_pessoa }}</h1>
        <div class="buttons">
          <button
            @click="deletarPessoa()"
            class="button is-danger"
          >
            Deletar pessoa
          </button>
        </div>
      </div>
      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="pessoa.nome"
              />
            </div>
          </div>

          <div class="field">
            <label>Primeiro Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="pessoa.primeiro_nome"
              />
            </div>
          </div>

          <div class="field">
            <label>E-mail</label>
            <tooltip-email>
              <input
                type="email"
                class="input"
                v-model="pessoa.email"
                disabled
              />
            </tooltip-email>
          </div>

          <div class="field">
            <label>Telefone</label>
            <div class="control">
              <input-telefone v-model="pessoa.telefone" />
            </div>
          </div>

          <div class="field">
            <label>Função</label>
            <div class="control">
              <div class="select">
                <select v-model="pessoa.funcao">
                  <option value="PO">PO</option>
                  <option value="HEAD">HEAD</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <label>Situação</label>
            <div class="control">
              <div class="select">
                <select v-model="pessoa.ativo">
                  <option :value="true">Ativo</option>
                  <option :value="false">Inativo</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <button
              class="button is-success"
              type="submit"
            >Editar pessoa</button>
            <botao-voltar />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { toast } from 'bulma-toast'
import BotaoVoltar from '../../../components/BotaoVoltar'
import InputTelefone from '../../../components/InputTelefone'
import TooltipEmail from '../../../components/TooltipEmail'

export default {
    components: {
        BotaoVoltar,
        InputTelefone,
        TooltipEmail
    },
    name: 'Pessoa',
    data() {
        return {
            notification: false,
            pessoa: {},
        }
    },
    mounted() {
        this.getPessoa()
    },
    methods: {
        async deletarPessoa() {
            var resultado = confirm('Deseja mesmo deletar o pessoa?')
            if (!resultado) {
                return
            }

            this.$store.commit('setIsLoading', true)

            const IDpessoa = this.$route.params.id
            await axios
                .delete(`/api/v1/pessoas/${IDpessoa}/`)
                .then(response => {
                    toast({
                        message: `${this.pessoa.primeiro_nome} foi removido`,
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'bottom-right',
                    })
                    this.$router.push(`/dashboard/pessoas`)
                })
                .catch(error => {
                    toast({
                        message: `Ocorreu um erro ao remover ${this.pessoa.primeiro_nome}`,
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'bottom-right',
                    })
                    console.error(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getPessoa() {
            this.$store.commit('setIsLoading', true)

            const IDpessoa = this.$route.params.id

            await axios
                .get(`/api/v1/pessoas/${IDpessoa}/`)
                .then(response => {
                    this.pessoa = response.data
                })
                .catch(error => {
                    toast({
                        message: `Ocorreu um erro ao buscar os dados`,
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                    console.error(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            const IDpessoa = this.$route.params.id

            await axios
                .patch(`/api/v1/pessoas/${IDpessoa}/`, this.pessoa)
                .then(response => {
                    toast({
                        message: `Os dados de ${this.pessoa.primeiro_nome} foram atualizados`,
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push(`/dashboard/pessoas`)
                })
                .catch(error => {
                    toast({
                        message: `Ocorreu um erro ao atualizar os dados de ${this.pessoa.primeiro_nome}`,
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },
    },
};
</script>
