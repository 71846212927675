<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Escritórios Regionais</h1>
      </div>
      <div class="column is-12">
        <v-data-table
          :headers="headers"
          :items="ers"
          item-key="id_er"
          class="elevation-1"
          :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'mdi-arrow-collapse-left',
                        lastIcon: 'mdi-arrow-collapse-right',
                        prevIcon: 'mdi-minus',
                        nextIcon: 'mdi-plus',
                    }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <router-link :to="{
                                name: 'Er',
                                params: { id: item.id_er },
                            }">
              Editar
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Ers',
    data() {
        return {
            ers: [],
            headers: [
                { text: 'Nome', value: 'nome_er' },
                { text: 'Email', value: 'email_er' },
                { text: 'Gerente', value: 'gerente_er' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
        };
    },
    mounted() {
        this.getErs();
    },
    methods: {
        async getErs() {
            this.$store.commit('setIsLoading', true);

            axios
                .get('/api/v1/ers/')
                .then(response => {
                    this.ers = response.data;
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
