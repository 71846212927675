// import { createRouter, createWebHistory } from 'vue-router';
import Vue from 'vue';
import VueRouter from 'vue-router';

import SignUp from '../views/SignUp.vue';
import LogIn from '../views/LogIn.vue';
import MinhaConta from '../views/dashboard/conta/MinhaConta.vue';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Ciclos from '../views/dashboard/ciclo/Ciclos.vue';
import IniciarCiclo from '../views/dashboard/ciclo/IniciarCiclo.vue';
import EditarCiclo from '../views/dashboard/ciclo/EditarCiclo.vue';
import Agencias from '../views/dashboard/agencia/Agencias.vue';
import AdicionarAgencia from '../views/dashboard/agencia/AdicionarAgencia.vue';
import EditarAgencia from '../views/dashboard/agencia/EditarAgencia.vue';
import Empresas from '../views/dashboard/empresa/Empresas.vue';
import Empresa from '../views/dashboard/empresa/Empresa.vue';
import EditarEmpresa from '../views/dashboard/empresa/EditarEmpresa.vue';
import ConfigurarEmpresa from '../views/dashboard/empresa/ConfigurarEmpresa.vue';
import SepararEmpresas from '../views/dashboard/empresa/SepararEmpresas.vue';
import Produtos from '../views/dashboard/produto/Produtos.vue';
import Produto from '../views/dashboard/produto/Produto.vue';
import Pessoas from '../views/dashboard/pessoa/Pessoas.vue';
import Pessoa from '../views/dashboard/pessoa/Pessoa.vue';
import AdicionarPessoa from '../views/dashboard/pessoa/AdicionarPessoa.vue';
import Ers from '../views/dashboard/er/Ers.vue';
import Er from '../views/dashboard/er/Er.vue';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/cadastrar-se',
        name: 'SignUp',
        component: SignUp,
    },
    {
        path: '/entrar',
        name: 'LogIn',
        component: LogIn,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/minha-conta',
        name: 'MinhaConta',
        component: MinhaConta,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/ciclos',
        name: 'Ciclos',
        component: Ciclos,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/ciclos/iniciar-ciclo',
        name: 'IniciarCiclo',
        component: IniciarCiclo,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/ciclos/:id',
        name: 'EditarCiclo',
        component: EditarCiclo,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/agencias',
        name: 'Agencias',
        component: Agencias,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/agencias/adicionar-agencia',
        name: 'AdicionarAgencia',
        component: AdicionarAgencia,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/agencias/:id',
        name: 'EditarAgencia',
        component: EditarAgencia,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/empresas',
        name: 'Empresas',
        component: Empresas,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/empresas/:id',
        name: 'Empresa',
        component: Empresa,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/empresas/:id/editar',
        name: 'EditarEmpresa',
        component: EditarEmpresa,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/empresas/:id/configurar',
        name: 'ConfigurarEmpresa',
        component: ConfigurarEmpresa,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/empresas/separar/:ciclo',
        name: 'SepararEmpresas',
        component: SepararEmpresas,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/empresas/:id/produtos',
        name: 'Produtos',
        component: Produtos,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/empresas/:id/produtos/:produto',
        name: 'Produto',
        component: Produto,
        meta: {
            requireLogin: true,
        },
    },
    {
        path: '/dashboard/pessoas/',
        name: 'Pessoas',
        component: Pessoas,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/pessoas/:id/editar',
        name: 'Pessoa',
        component: Pessoa,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/pessoas/adicionar',
        name: 'AdicionarPessoa',
        component: AdicionarPessoa,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/ers/',
        name: 'Ers',
        component: Ers,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
    {
        path: '/dashboard/ers/:id/editar',
        name: 'Er',
        component: Er,
        meta: {
            requireLogin: true,
            requireAdmin: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let store = router.app.$store
    store.commit('initializeStore')

    if (
        to.matched.some(record => record.meta.requireLogin) &&
        !store.state.isAuthenticated
    ) {
        next('/entrar');
    } else if (to.matched.some(record => record.meta.requireAdmin) && !store.getters.esAdministrador) {
        next('/dashboard')
    } else {
        next();
    }
});

export default router;
