<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Editar ER {{ er.nome_er }}</h1>
        <div class="buttons">
          <button
            @click="deletarEr()"
            class="button is-danger"
          >
            Deletar er
          </button>
        </div>
      </div>
      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="er.nome_er"
              />
            </div>
          </div>

          <div class="field">
            <label>Gerente</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="er.gerente_er"
              />
            </div>
          </div>

          <div class="field">
            <label>E-mail</label>
            <div class="control">
              <input
                type="email"
                class="input"
                v-model="er.email_er"
              />
            </div>
          </div>

          <div class="field">
            <button class="button is-success">Editar escritório</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { toast } from 'bulma-toast';

export default {
    name: 'Er',
    data() {
        return {
            notification: false,
            er: {},
        };
    },
    mounted() {
        this.getEr();
    },
    methods: {
        async getEr() {
            this.$store.commit('setIsLoading', true);

            const IDer = this.$route.params.id;

            await axios
                .get(`/api/v1/ers/${IDer}/`)
                .then(response => {
                    this.er = response.data;
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            this.$store.commit('setIsLoading', false);
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true);

            const IDer = this.$route.params.id;

            await axios
                .patch(`/api/v1/ers/${IDer}/`, this.er)
                .then(response => {
                    toast({
                        message: 'O escritório regional foi atualizado',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });

                    this.$router.push(`/dashboard/ers`);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
