export const legenda_produtos = {
                        'mk': [
                            { legenda: 'Descrição produto', valor: 'f2_desc_prod' },
                            { legenda: 'f2_pq_prod', valor: 'f2_pq_prod' },
                            {
                                legenda: 'Instrução',
                                valor: 'f2_instrucao_prod',
                            },
                            {
                                legenda: 'Duvidas',
                                valor: 'f2_quais_duvidas_prod',
                            },
                            {
                                legenda: 'Marca',
                                valor: 'f2_marca_prod',
                            },
                            {
                                legenda: 'Modelo',
                                valor: 'f2_modelo_prod',
                            },
                            {
                                legenda: 'Categoria',
                                valor: 'f2_categoria_prod',
                            },
                            {
                                legenda: 'Dimensão',
                                valor: 'f2_dimensao_prod',
                            },
                            { legenda: 'Peso', valor: 'f2_peso_prod' },
                            {
                                legenda: 'Mais caracteristicas',
                                valor: 'f2_demais_caract_prod',
                            },
                            {
                                legenda: 'Condição',
                                valor: 'f2_condicao_prod',
                            },
                            {
                                legenda: 'Legenda',
                                valor: 'f2_preco_prod',
                            },
                            {
                                legenda: 'Qunatidade',
                                valor: 'f2_quantidade_prod',
                            },
                            {
                                legenda: 'Código de barras',
                                valor: 'f2_codigo_barra_prod',
                            },
                        ],
                        'rs': [
                            { legenda: 'Descrição produto', valor: 'f2_desc_prod' },
                            { legenda: 'f2_pq_prod', valor: 'f2_pq_prod' },
                            {
                                legenda: 'Preço',
                                valor: 'f2_preco_prod',
                            },
                            {
                                legenda: 'Condição',
                                valor: 'f2_norma_condicao_prod',
                            },
                        ],
};
export const legenda_empresas = {
           'mk': [
               {
                   legenda: 'Dt Form 1',
                   valor: 'carimbo_mk',
               },
               {
                   legenda: 'Consultor ER',
                   valor: 'consultor_mk',
               },
               {
                   legenda: 'Nome Cliente',
                   valor: 'nome_cliente_mk',
               },
               {
                   legenda: 'CPF',
                   valor: 'cpf_mk',
               },
               {
                   legenda: 'Email ',
                   valor: 'email_mk',
               },
               {
                   legenda: 'Telefone',
                   valor: 'telefone_mk',
               },
               {
                   legenda: 'Celular',
                   valor: 'celular_mk',
               },
               {
                   legenda: 'Melhor período para contato',
                   valor: 'melhor_dia_contato_mk',
               },
               {
                   legenda: 'Descrição Empresa',
                   valor: 'descricao_empresa_mk',
               },
               {
                   legenda: 'Porte',
                   valor: 'porte_empresa_mk',
               },
               {
                   legenda: 'Nome Fantasia',
                   valor: 'nome_fantasia_mk',
               },
               {
                   legenda: 'Razão Social',
                   valor: 'razao_social_mk',
               },
               {
                   legenda: 'CNPJ',
                   valor: 'cnpj_mk',
               },
               {
                   legenda: 'Setor',
                   valor: 'setor_mk',
               },
               {
                   legenda: 'Categoria',
                   valor: 'categoria_mk',
               },
               {
                   legenda: 'Telefone Coml',
                   valor: 'telefone_comercial_mk',
               },
               {
                   legenda: 'Whatsapp Coml',
                   valor: 'whatsapp_comercial_mk',
               },
               {
                   legenda: 'Cep ',
                   valor: 'cep_mk',
               },
               {
                   legenda: 'Endereco',
                   valor: 'endereco_mk',
               },
               {
                   legenda: 'Horario Funcionamento',
                   valor: 'horario_funcionamento_mk',
               },
               {
                   legenda: 'Interesse ML/ELO7',
                   valor: 'interesse_mk',
               },
               {
                   legenda: 'Plataforma',
                   valor: 'plataforma_mk',
               },
               {
                   legenda: 'Projeto Futuro',
                   valor: 'futuro_mk',
               },
               {
                   legenda: 'Produto 1',
                   valor: 'produto1_mk',
               },
               {
                   legenda: 'Produto 2',
                   valor: 'produto2_mk',
               },
               {
                   legenda: 'Produto 3',
                   valor: 'produto3_mk',
               },
               {
                   legenda: 'Produto 4',
                   valor: 'produto4_mk',
               },
               {
                   legenda: 'Produto 5',
                   valor: 'produto5_mk',
               },
               {
                   legenda: 'Produto 6',
                   valor: 'produto6_mk',
               },
               {
                   legenda: 'Produto 7',
                   valor: 'produto7_mk',
               },
               {
                   legenda: 'Produto 8',
                   valor: 'produto8_mk',
               },
               {
                   legenda: 'Produto 9',
                   valor: 'produto9_mk',
               },
               {
                   legenda: 'Produto 10',
                   valor: 'produto10_mk',
               },
               {
                   legenda: 'Quem compra de você',
                   valor: 'publico_alvo_mk',
               },
               {
                   legenda: 'O que te faz único?',
                   valor: 'o_que_te_faz_unico_mk',
               },
               {
                   legenda:
                       'Por que eles devem comprar de você e não de seus concorrentes?',
                   valor: 'pq_comprar_seu_mk',
               },
               {
                   legenda: 'Sistema NF',
                   valor: 'sistema_nota_fiscal_mk',
               },
               {
                   legenda: 'ERP',
                   valor: 'erp_mk',
               },
               {
                   legenda: 'Apto NF',
                   valor: 'apto_nota_fiscal_mk',
               },
               {
                   legenda: 'Informações adicionais',
                   valor: 'info_adicional_mk',
               },
               {
                   legenda: 'Emite NF',
                   valor: 'emite_nf_mk',
               },
               {
                   legenda: 'Certificado Eletrônico',
                   valor: 'certificado_eletronico_mk',
               },
               {
                   legenda: 'Alguma dúvida ?',
                   valor: 'duvida_mk',
               },
               {
                   legenda: 'Responsável Form 2',
                   valor: 'f2_nome_responsavel',
               },
               {
                   legenda: 'Nome Fantasia',
                   valor: 'f2_nome_fantasia',
               },
               {
                   legenda: 'CNPJ',
                   valor: 'f2_cnpj',
               },
               {
                   legenda: 'Possui site',
                   valor: 'f2_possui_site',
               },
               {
                   legenda: 'Possui FB',
                   valor: 'f2_possui_face',
               },
               {
                   legenda: 'Possui IM',
                   valor: 'f2_possui_insta',
               },
               {
                   legenda: 'Possui Perfil MKT',
                   valor: 'f2_possui_perfil_mkt',
               },
               {
                   legenda: '3 produtos mais vendidos',
                   valor: 'f2_3_produtos_mais',
               },
               {
                   legenda: 'Algo a mais...',
                   valor: 'f2_algo_mais',
               },
               {
                   legenda: 'Data Form 2',
                   valor: 'f2_carimbo',
               },
           ],
           'rs': [
               {
                   legenda: 'Dt Form 1',
                   valor: 'carimbo_rs',
               },
               {
                   legenda: 'Consultor ER',
                   valor: 'consultor_rs',
               },
               {
                   legenda: 'Nome Cliente',
                   valor: 'nome_cliente_rs',
               },
               {
                   legenda: 'CPF',
                   valor: 'cpf_rs',
               },
               {
                   legenda: 'Email',
                   valor: 'email_rs',
               },
               {
                   legenda: 'Telefone',
                   valor: 'telefone_rs',
               },
               {
                   legenda: 'Celular',
                   valor: 'celular_rs',
               },
               {
                   legenda: 'Melhor período para contato',
                   valor: 'melhor_dia_contato_rs',
               },
               {
                   legenda: 'Perfil FB Pessoal',
                   valor: 'perfil_pessoal_facebook',
               },
               {
                   legenda: 'Descrição Empresa',
                   valor: 'descricao_empresa_rs',
               },
               {
                   legenda: 'Porte',
                   valor: 'porte_empresa_rs',
               },
               {
                   legenda: 'Nome Fantasia',
                   valor: 'nome_fantasia_rs',
               },
               {
                   legenda: 'Razão Social',
                   valor: 'razao_social_rs',
               },
               {
                   legenda: 'CNPJ',
                   valor: 'cnpj_rs',
               },
               {
                   legenda: 'Setor',
                   valor: 'setor_rs',
               },
               {
                   legenda: 'Categoria',
                   valor: 'categoria_rs',
               },
               {
                   legenda: 'Telefone Coml',
                   valor: 'telefone_comercial_rs',
               },
               {
                   legenda: 'Whatsapp Coml',
                   valor: 'whatsapp_comercial_rs',
               },
               {
                   legenda: 'Cep ',
                   valor: 'cep_rs',
               },
               {
                   legenda: 'Endereco',
                   valor: 'endereco_rs',
               },
               {
                   legenda: 'Horario Funcionamento',
                   valor: 'horario_funcionamento_rs',
               },
               {
                   legenda: 'Comodidades',
                   valor: 'comodidades',
               },
               {
                   legenda: 'Acessibilidade',
                   valor: 'acessibilidade',
               },
               {
                   legenda: 'Possui Logomarca',
                   valor: 'possui_logomarca',
               },
               {
                   legenda: 'Site Loja Virtual',
                   valor: 'site_loja_virtual',
               },
               {
                   legenda: 'Facebook ',
                   valor: 'facebook_empresa',
               },
               {
                   legenda: 'Instagram ',
                   valor: 'instagram_empresa',
               },
               {
                   legenda: 'GMN ',
                   valor: 'gmn_empresa',
               },
               {
                   legenda: 'Whatsapp Business',
                   valor: 'whatsapp_business',
               },
               {
                   legenda: 'Numero Whatsapp',
                   valor: 'numero_whatsapp',
               },
               {
                   legenda: 'Msg Automatica Whatsapp',
                   valor: 'msg_automatica_whatsapp',
               },
               {
                   legenda: 'Msgs Whatsapp Utilizadas',
                   valor: 'msgs_whatsapp_utilizadas',
               },
               {
                   legenda: 'Publico Alvo Rs',
                   valor: 'publico_alvo_rs',
               },
               {
                   legenda: 'O Que Te Faz Unico Rs',
                   valor: 'o_que_te_faz_unico_rs',
               },
               {
                   legenda: 'Região Clientes',
                   valor: 'regiao_clientes',
               },
               {
                   legenda: 'Porque Comprar',
                   valor: 'pq_comprar_seu_rs',
               },
               {
                   legenda: 'Informações adicionais',
                   valor: 'info_adicional_rs',
               },
               {
                   legenda: 'Dúvidas',
                   valor: 'duvida_rs',
               },
               {
                   legenda: 'Responsável Form 2',
                   valor: 'f2_nome_responsavel',
               },
               {
                   legenda: '',
                   valor: 'f2_nome_fantasia',
               },
               {
                   legenda: '',
                   valor: 'f2_cnpj',
               },
               {
                   legenda: 'Slogan',
                   valor: 'f2_slogan',
               },
               {
                   legenda: 'Formas de Pagamento',
                   valor: 'f2_formas_pagto',
               },
               {
                   legenda: 'Loja FB',
                   valor: 'f2_possui_face',
               },
               {
                   legenda: 'Tudo sobre produto',
                   valor: 'f2_tudo_sobre_produto',
               },
               {
                   legenda: '3 produtos mais vendidos',
                   valor: 'f2_3_produtos_mais',
               },
               {
                   legenda: 'Algo a mais',
                   valor: 'f2_algo_mais',
               },
               {
                   legenda: 'Data Form 2',
                   valor: 'f2_carimbo',
               },
           ],
       };
                    