<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Adicionar Pessoa</h1>
      </div>
      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="pessoa.nome"
                required
              />
            </div>
          </div>
          <div class="field">
            <label>Primeiro Nome</label>
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="pessoa.primeiro_nome"
                required
              />
            </div>
          </div>
          <div class="field">
            <label>E-mail</label>
            <div class="control">
              <input
                type="email"
                class="input"
                v-model="pessoa.email"
                required
              />
            </div>
          </div>
          <div class="field">
            <label>Telefone</label>
            <div class="control">
              <input-telefone v-model="pessoa.telefone" />
            </div>
          </div>
          <div class="field">
            <label>Função</label>
            <div class="control">
              <div class="select">
                <select
                  v-model="pessoa.funcao"
                  required
                >
                  <option value="PO">PO</option>
                  <option value="HEAD">HEAD</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label>Situação</label>
            <div class="control">
              <div class="select">
                <select
                  v-model="pessoa.ativo"
                  required
                >
                  <option :value="true">Ativo</option>
                  <option :value="false">Inativo</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <button class="button is-success">Adicionar pessoa</button>
            <botao-voltar />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import BotaoVoltar from '../../../components/BotaoVoltar'
import InputTelefone from '../../../components/InputTelefone'
import { toast } from 'bulma-toast';

export default {
    name: 'AdicionarPessoa',
    components: {
        BotaoVoltar,
        InputTelefone
    },
    data() {
        InputTelefone
        return {
            notification: false,
            pessoa: {
                ativo: true,
                telefone: ''
            },
            telefoneFormatado: null
        };
    },
    mounted() {
    },
    methods: {
        async submitForm() {
            this.$store.commit('setIsLoading', true);

            await axios
                .post(`/api/v1/pessoas/`, this.pessoa)
                .then(response => {
                    toast({
                        message: 'O pessoa foi adicionada',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });

                    this.$router.push(`/dashboard/pessoas`);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
