import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: {
            id: 0,
            usename: '',
            funcao: '',
        },
        isLoading: false,
        isAuthenticated: false,
        token: '',
        legenda_produtos: {
            Marketplace: [
                { legenda: 'f2_desc_prod', valor: 'f2_desc_prod' },
                { legenda: 'f2_pq_prod', valor: 'f2_pq_prod' },
                {
                    legenda: 'f2_instrucao_prod',
                    valor: 'f2_instrucao_prod',
                },
                {
                    legenda: 'f2_quais_duvidas_prod',
                    valor: 'f2_quais_duvidas_prod',
                },
                {
                    legenda: 'f2_marca_prod',
                    valor: 'f2_marca_prod',
                },
                {
                    legenda: 'f2_modelo_prod',
                    valor: 'f2_modelo_prod',
                },
                {
                    legenda: 'f2_categoria_prod',
                    valor: 'f2_categoria_prod',
                },
                {
                    legenda: 'f2_dimensao_prod',
                    valor: 'f2_dimensao_prod',
                },
                { legenda: 'f2_peso_prod', valor: 'f2_peso_prod' },
                {
                    legenda: 'f2_demais_caract_prod',
                    valor: 'f2_demais_caract_prod',
                },
                {
                    legenda: 'f2_condicao_prod',
                    valor: 'f2_condicao_prod',
                },
                {
                    legenda: 'f2_preco_prod',
                    valor: 'f2_preco_prod',
                },
                {
                    legenda: 'f2_quantidade_prod',
                    valor: 'f2_quantidade_prod',
                },
                {
                    legenda: 'f2_codigo_barra_prod',
                    valor: 'f2_codigo_barra_prod',
                },
            ],
            'Redes Sociais': [
                { legenda: 'f2_desc_prod', valor: 'f2_desc_prod' },
                { legenda: 'f2_pq_prod', valor: 'f2_pq_prod' },
                {
                    legenda: 'f2_preco_prod',
                    valor: 'f2_preco_prod',
                },
                {
                    legenda: 'f2_condicao_prod',
                    valor: 'f2_norma_condicao_prod',
                },
            ],
        },
    },
    getters: {
        esAdministrador: state => {
            return state.isAuthenticated && !!state.user && state.user.funcao === 'Administrador'
        },
        user: (state) => state.user
    },
    mutations: {
        initializeStore(state) {
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token');
                state.isAuthenticated = true;

                axios.defaults.headers.common['Authorization'] =
                    'Token ' + state.token;

                state.user = {
                    id: localStorage.getItem('id'),
                    username: localStorage.getItem('username'),
                    funcao: localStorage.getItem('funcao')
                }

                if (!state.user.id || !state.user.username || !state.user.funcao) {
                    this.commit('inicializarUsuario')
                }
            } else {
                state.token = '';
                state.isAuthenticated = false;
                state.user = {};
            }
        },
        setIsLoading(state, status) {
            state.isLoading = status;
        },
        setToken(state, token) {
            state.token = token;
            state.isAuthenticated = true;
        },
        removeToken(state) {
            state.token = '';
            state.isAuthenticated = false;
        },
        setUser(state, user) {
            state.user = user;
        },
        inicializarUsuario(state) {
            axios.get('/api/v1/users/me/').then(response => {
                const idUsuario = response.data.id
                const nomeUsuario = response.data.username

                axios.get(`/api/v1/usuarios/${idUsuario}/`).then(response => {
                    const esAdmin = response.data.is_staff === 1
                    state.user = {
                        id: idUsuario,
                        username: nomeUsuario,
                        funcao: esAdmin ? 'Administrador' : 'PO'
                    }

                    localStorage.setItem('id', state.user.id)
                    localStorage.setItem('username', state.user.username)
                    localStorage.setItem('funcao', state.user.funcao)
                })
            })
        },
    },
    actions: {},
    modules: {},
});

export default store;