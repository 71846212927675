<template>
  <div class="container">
    <div class="column is-multiline">
      <div class="column is-12">
        <h1 class="title">Editar Ciclo {{ ciclo.id_ciclo }}</h1>
        <div class="buttons">
          <button
            @click="deletarCiclo()"
            class="button is-danger"
          >
            Deletar ciclo
          </button>
          <router-link
            class="button is-info"
            :to="{
                            name: 'SepararEmpresas',
                            params: { ciclo: $route.params.id },
                        }"
          >Separar empresas</router-link>
        </div>
      </div>
      <div class="column is-12">
        <form @submit.prevent="submitForm()">
          <div class="field">
            <label>Qt. Redes Sociais</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="ciclo.qt_rs"
              />
            </div>
          </div>

          <div class="field">
            <label>Qt. Marketplace</label>
            <div class="control">
              <input
                type="number"
                class="input"
                v-model="ciclo.qt_mk"
              />
            </div>
          </div>

          <div class="field">
            <label>Dt. entrada</label>
            <div class="control">
              <input
                type="date"
                class="input"
                v-model="ciclo.dt_entrada"
              />
            </div>
          </div>

          <div class="field">
            <label>Status</label>
            <div class="control">
              <div class="select">
                <select v-model="ciclo.ic_concluido">
                  <option value="0">Em andamento</option>
                  <option value="1">Concluido</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field">
            <button
              class="button is-success"
              type="submit"
            >Editar ciclo</button>
            <botao-voltar />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import BotaoVoltar from '../../../components/BotaoVoltar'
import { toast } from 'bulma-toast';

export default {
    name: 'EditarCiclo',
    components: {BotaoVoltar},
    data() {
        return {
            notification: false,
            ciclo: {},
        };
    },
    mounted() {
        this.getCiclo();
    },
    methods: {
        async deletarCiclo() {
            var resultado = confirm('Deseja mesmo deletar o ciclo?');
            if (!resultado) {
                return;
            }

            this.$store.commit('setIsLoading', true);

            const IDciclo = this.$route.params.id;
            await axios
                .post(`/api/v1/ciclos/deletar_ciclo/${IDciclo}/`)
                .then(response => {
                    console.log(response.data);

                    this.$router.push(`/dashboard/ciclos`);
                })
                .catch(error => {
                    console.error(error);
                });
            this.$store.commit('setIsLoading', false);
        },
        async getCiclo() {
            this.$store.commit('setIsLoading', true);

            const IDciclo = this.$route.params.id;

            await axios
                .get(`/api/v1/ciclos/${IDciclo}/`)
                .then(response => {
                    this.ciclo = response.data;
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            this.$store.commit('setIsLoading', false);
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true);

            const IDciclo = this.$route.params.id;

            await axios
                .patch(`/api/v1/ciclos/${IDciclo}/`, this.ciclo)
                .then(response => {
                    toast({
                        message: 'O ciclo foi atualizado',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });

                    this.$router.push(`/dashboard/ciclos`);
                })
                .catch(error => {
                    console.log(error);
                });

            this.$store.commit('setIsLoading', false);
        },
    },
};
</script>
