<template>
  <input
    type="tel"
    placeholder="12 91234-5678"
    class="input"
    :value="telefoneFormatado"
    @input="formatarTelefone($event.target.value)"
  />
</template>

<script>
export default {
    name: 'InputTelefone',
    data() {
        return {
            telefoneFormatado: null
        }
    },
    methods: {
        formatarTelefone(value) {
            const telefone = value.replace(/[\D]/g, '').substr(0, 11)
            this.telefoneFormatado = `${telefone.substring(0, 2)}`
            if (telefone.length === 10) {
                this.telefoneFormatado = `${telefone.substring(0, 2)} ${telefone.substr(2, 4)}-${telefone.substr(6, 4)}`
            } else {
                if (telefone.length > 2)
                    this.telefoneFormatado += ` ${telefone.substr(2, 5)}`
                if (telefone.length > 7)
                    this.telefoneFormatado += `-${telefone.substr(7, 4)}`
            }
            this.$emit('input', telefone)
        },
    },
}
</script>

<style>
</style>